import instance2 from "../axios"; //Essa instance é da API de Broker;
import environment from "../environment";
import instance from "./kanban-axios.instance"; //Essa instance é da API do Mario;

//const kanbanUrl = "https://localhost:44353"; //remover
//const kanbanUrl = "https://gclaimsonpointkanbanapi-dev.azurewebsites.net"; //remover

class KanbanProvider {
    async getPipes() {
        const applicationConfig = environment.getApplicationConfig();
        const companyKey = (applicationConfig || {}).companyKey;
        //((applicationConfig || {}).kanban || {}).url = kanbanUrl;

        return await instance({
            method: "get",
            url: "/api/kanban/v1.0/pipes",
            baseURL: ((applicationConfig || {}).kanban || {}).url,
            headers: {
                companyKey,
                "Content-Type": "application/json"
            }
        });
    }

    async getPipeSteps(pipeId, filterCardsValues) {
        const applicationConfig = environment.getApplicationConfig();
        const companyKey = (applicationConfig || {}).companyKey;
        //((applicationConfig || {}).kanban || {}).url = kanbanUrl;

        // filterCardsValues = this.removeMaskFromCpfCnpj(filterCardsValues);

        return await instance({
            method: "get",
            url: `/api/kanban/v1.0/pipes/${pipeId}/steps/filter?filterCardsValues=${filterCardsValues}`,
            baseURL: ((applicationConfig || {}).kanban || {}).url,
            headers: {
                companyKey,
                "Content-Type": "application/json"
            }
        });
    }

    async getStepCards(pipeId, stepId, skip, filterCardsValues) {
        const applicationConfig = environment.getApplicationConfig();
        const companyKey = (applicationConfig || {}).companyKey;
        //((applicationConfig || {}).kanban || {}).url = kanbanUrl;

        return await instance({
            method: "get",
            url: `/api/kanban/v1.0/pipes/${pipeId}/steps/${stepId}/skip/${skip}/filterCardsValues?filterCardsValues=${filterCardsValues}`,
            baseURL: ((applicationConfig || {}).kanban || {}).url,
            headers: {
                companyKey,
                "Content-Type": "application/json"
            }
        });
    }

    async  getPipe(pipeId) {
        const applicationConfig = environment.getApplicationConfig();
        const companyKey = (applicationConfig || {}).companyKey;
        //((applicationConfig || {}).kanban || {}).url = kanbanUrl;

        return await instance({
            method: "get",
            url: `/api/kanban/v1.0/pipes/${pipeId}`,
            baseURL: ((applicationConfig || {}).kanban || {}).url,
            headers: {
                companyKey,
                "Content-Type": "application/json"
            }
        });
    }

    removeMaskFromCpfCnpj(param) {
        let valoresDoFiltroTratado = param.replace(".", "");

        valoresDoFiltroTratado = valoresDoFiltroTratado.replace(".", "");

        valoresDoFiltroTratado = valoresDoFiltroTratado.replace("/", "");

        valoresDoFiltroTratado = valoresDoFiltroTratado.replace("-", "");

        return valoresDoFiltroTratado;
    }

    async filterAllCards(pipeId, filterCardsValues) {
        let valuesFilters = this.removeMaskFromCpfCnpj(filterCardsValues);

        const applicationConfig = environment.getApplicationConfig();

        const companyKey = (applicationConfig || {}).companyKey;
        //((applicationConfig || {}).kanban || {}).url = kanbanUrl;

        return await instance({
            method: "get",

            url: `/api/kanban/v1.0/pipes/${pipeId}/${valuesFilters}/steps/filter`,

            baseURL: ((applicationConfig || {}).kanban || {}).url,

            headers: {
                companyKey,

                "Content-Type": "application/json"
            }
        });
    }

    async moveCard(_cardId, fromStepId, toStepId) {
        const applicationConfig = environment.getApplicationConfig();
        const companyKey = (applicationConfig || {}).companyKey;
        //((applicationConfig || {}).kanban || {}).url = kanbanUrl;

        return await instance({
            method: "post",
            url: `/api/kanban/v1.0/steps/${fromStepId}/card-navigation`,
            baseURL: ((applicationConfig || {}).kanban || {}).url,
            headers: {
                companyKey,
                "Content-Type": "application/json"
            },
            data: {
                cardId: _cardId,
                stepToId: toStepId
            }
        });
    }

    async getCardCCGInfo(ref) {
        return await instance2
            .get(
                `/api/kanban/GetCardPolicyHolderCCGInfo?PolicyHolderCCGUniqueId=${ref}`
            )
            .then(data => (data.data || {}).Response)
            .catch(data => {
                if (data && data.response && data.response.data)
                    return Promise.reject(data.response.data.Errors);
                return Promise.reject("Erro ao tentar executar a solicitação.");
            });
    }

    async getCardBrokerInfo(ref) {
        return await instance2
            .get(`/api/kanban/GetCardBrokerInfo?BrokerUniqueId=${ref}`)
            .then(data => (data.data || {}).Response)
            .catch(data => {
                if (data && data.response && data.response.data)
                    return Promise.reject(data.response.data.Errors);
                return Promise.reject("Erro ao tentar executar a solicitação.");
            });
    }

    async getCardRamoAtividadeInfo(ref) {
        return await instance2
            .get(`/api/kanban/GetCardBranchActivityInfo?PolicyHolderUniqueId=${ref}`)
            .then(data => (data.data || {}).Response)
            .catch(data => {
                if (data && data.response && data.response.data)
                    return Promise.reject(data.response.data.Errors);
                return Promise.reject("Erro ao tentar executar a solicitação.");
            });
    }

    async getRequestLimitInfo(ref) {
        return await instance2
            .get(`/api/kanban/GetCardRequestLimitInfo?PolicyHolderUniqueId=${ref}`)
            .then(data => (data.data || {}).Response)
            .catch(data => {
                if (data && data.response && data.response.data)
                    return Promise.reject(data.response.data.Errors);
                return Promise.reject("Erro ao tentar executar a solicitação.");
            });
    }

    async getUrlFileDownload(ref) {
        return await instance2
            .get(`/api/Application/GetUrlFileDownload?ArchiveUniqueId=${ref}`)
            .then(data => (data.data || {}).Response)
            .catch(data => {
                if (data && data.response && data.response.data)
                    return Promise.reject(data.response.data.Errors);

                return Promise.reject("Erro ao tentar executar a solicitação.");
            });
    }

    async getCardPolicyHolderNomeationRequestInfo(ref) {
        return await instance2
            .get(
                `/api/kanban/GetCardPolicyHolderNomeationRequestInfo?PolicyHolderNomeationRequestId=${ref}`
            )
            .then(data => (data.data || {}).Response)
            .catch(data => {
                if (data && data.response && data.response.data)
                    return Promise.reject(data.response.data.Errors);
                return Promise.reject("Erro ao tentar executar a solicitação.");
            });
    }

    async getCardProposalInfo(ref) {
        return await instance2
            .get(`/api/kanban/GetCardProposalInfo?ProposalUniqueId=${ref}`)
            .then(data => (data.data || {}).Response)
            .catch(data => {
                if (data && data.response && data.response.data)
                    return Promise.reject(data.response.data.Errors);
                return Promise.reject("Erro ao tentar executar a solicitação.");
            });
    }

    async getCardEndorsementInfo(ref) {
        return await instance2
            .get(`/api/kanban/GetCardEndorsementInfo?ProposalUniqueId=${ref}`)
            .then(data => (data.data || {}).Response)
            .catch(data => {
                if (data && data.response && data.response.data)
                    return Promise.reject(data.response.data.Errors);

                return Promise.reject("Erro ao tentar executar a solicitação.");
            });
    }

    async getCardEconomicGroupInfo(ref) {
        return await instance2
            .get(`/api/kanban/GetCardEconomicGroupInfo?ProposalUniqueId=${ref}`)
            .then(data => (data.data || {}).Response)
            .catch(data => {
                if (data && data.response && data.response.data)
                    return Promise.reject(data.response.data.Errors);
                return Promise.reject("Erro ao tentar executar a solicitação.");
            });
    }

    async kanbanProposalRequestDocument(_data) {
        return await instance2({
                method: "post",
                url: "/api/kanban/KanbanProposalRequestDocument",
                data: _data
            })
            .then(data => (data.data || {}).Response)
            .catch(data => {
                if (data && data.response && data.response.data)
                    return Promise.reject(data.response.data.Errors);
                return Promise.reject("Erro ao tentar executar a solicitação.");
            });
    }

    async kanbanBrokerRequestDocument(_data) {
        return await instance2({
                method: "post",
                url: "/api/kanban/KanbanBrokerRequestDocument",
                data: _data
            })
            .then(data => (data.data || {}).Response)
            .catch(data => {
                if (data && data.response && data.response.data)
                    return Promise.reject(data.response.data.Errors);
                return Promise.reject("Erro ao tentar executar a solicitação.");
            });
    }

    async kanbanProposalChangeStatusRequestDocument(_data) {
        return await instance2({
                method: "post",
                url: "/api/kanban/KanbanProposalChangeStatusRequestDocument",
                data: _data
            })
            .then(data => (data.data || {}).Response)
            .catch(data => {
                if (data && data.response && data.response.data)
                    return Promise.reject(data.response.data.Errors);
                return Promise.reject("Erro ao tentar executar a solicitação.");
            });
    }

    async KanbanBrokerChangeStatusRequestDocument(_data) {
        return await instance2({
                method: "post",
                url: "/api/kanban/KanbanBrokerChangeStatusRequestDocument",
                data: _data
            })
            .then(data => (data.data || {}).Response)
            .catch(data => {
                if (data && data.response && data.response.data)
                    return Promise.reject(data.response.data.Errors);
                return Promise.reject("Erro ao tentar executar a solicitação.");
            });
    }

    async CreatePolicyHolderRequestLimitObservation(_data) {
        return await instance2({
                method: "post",
                url: "/api/policyHolder/CreatePolicyHolderRequestLimitObservation",
                data: _data
            })
            .then(data => (data.data || {}).Response)
            .catch(data => {
                if (data && data.response && data.response.data)
                    return Promise.reject(data.response.data.Errors);
                return Promise.reject("Erro ao tentar executar a solicitação.");
            });
    }

    async KanbanRequestLimitChangeStatusRequestDocument(_data) {
        return await instance2({
                method: "post",
                url: "/api/kanban/KanbanRequestLimitChangeStatusRequestDocument",
                data: _data
            })
            .then(data => (data.data || {}).Response)
            .catch(data => {
                if (data && data.response && data.response.data)
                    return Promise.reject(data.response.data.Errors);
                return Promise.reject("Erro ao tentar executar a solicitação.");
            });
    }

    async kanbanProposalSendToBroker(_data) {
        return await instance2({
                method: "post",
                url: "/api/kanban/KanbanProposalSendToBroker",
                data: _data
            })
            .then(data => (data.data || {}).Response)
            .catch(data => {
                if (data && data.response && data.response.data)
                    return Promise.reject(data.response.data.Errors);
                return Promise.reject("Erro ao tentar executar a solicitação.");
            });
    }

    async KanbanSendEmailRequestDocumentToBroker(_data) {
      return await instance2({
              method: "post",
              url: "/api/Kanban/KanbanSendEmailRequestDocumentToBroker",
              data: _data
          })
          .then(data => (data.data || {}).Response)
          .catch(data => {
              if (data && data.response && data.response.data)
                  return Promise.reject(data.response.data.Errors);
              return Promise.reject("Erro ao tentar executar a solicitação.");
          });
    }

    async KanbanSendEmailRequestDocumentToBrokerByPipe(_data) {
      return await instance2({
              method: "post",
              url: "/api/Kanban/KanbanSendEmailRequestDocumentToBrokerByPipe",
              data: _data
          })
          .then(data => (data.data || {}).Response)
          .catch(data => {
              if (data && data.response && data.response.data)
                  return Promise.reject(data.response.data.Errors);
              return Promise.reject("Erro ao tentar executar a solicitação.");
          });
    }

    async followUpSendObservation(_data) {
        return await instance2({
                method: "post",
                url: "/api/kanban/FollowUpSendObservation",
                data: _data
            })
            .then(data => (data.data || {}).Response)
            .catch(data => {
                if (data && data.response && data.response.data)
                    return Promise.reject(data.response.data.Errors);
                return Promise.reject("Erro ao tentar executar a solicitação.");
            });
    }

    async KanbanBrokerRequestDocumentsSendToBroker(_data) {
        return await instance2({
                method: "post",
                url: "/api/kanban/KanbanBrokerRequestDocumentsSendToBroker",
                data: _data
            })
            .then(data => (data.data || {}).Response)
            .catch(data => {
                if (data && data.response && data.response.data)
                    return Promise.reject(data.response.data.Errors);
                return Promise.reject("Erro ao tentar executar a solicitação.");
            });
    }

    async getRequestDocumentArchives(ref) {
        return await instance2
            .get(
                `/api/kanban/GetRequestDocumentArchives?RequestDocumentUniqueId=${ref}`
            )
            .then(data => (data.data || {}).Response)
            .catch(data => {
                if (data && data.response && data.response.data)
                    return Promise.reject(data.response.data.Errors);
                return Promise.reject("Erro ao tentar executar a solicitação.");
            });
    }

    async getArchiveLinkToDownload(ref) {
        return await instance2
            .get(`/api/Application/GetUrlFileDownload?ArchiveUniqueId=${ref}`)
            .then(data => (data.data || {}).Response)
            .catch(data => {
                if (data && data.response && data.response.data)
                    return Promise.reject(data.response.data.Errors);

                return Promise.reject("Erro ao tentar executar a solicitação.");
            });
    }

    async kanbanProposalDeleteRequestDocument(_data) {
        return await instance2({
                method: "post",
                url: "/api/kanban/KanbanProposalDeleteRequestDocument",
                data: _data
            })
            .then(data => (data.data || {}).Response)
            .catch(data => {
                if (data && data.response && data.response.data)
                    return Promise.reject(data.response.data.Errors);
                return Promise.reject("Erro ao tentar executar a solicitação.");
            });
    }

    async KanbanBrokerDeleteRequestDocument(_data) {
        return await instance2({
                method: "post",
                url: "/api/kanban/KanbanBrokerDeleteRequestDocument",
                data: _data
            })
            .then(data => (data.data || {}).Response)
            .catch(data => {
                if (data && data.response && data.response.data)
                    return Promise.reject(data.response.data.Errors);
                return Promise.reject("Erro ao tentar executar a solicitação.");
            });
    }

    async kanbanSaveEconomicGroup(_data) {
        return await instance2({
                method: "post",
                url: "/api/kanban/KanbanSaveEconomicGroup",
                data: _data
            })
            .then(data => (data.data || {}).Response)
            .catch(data => {
                if (data && data.response && data.response.data)
                    return Promise.reject(data.response.data.Errors);
                return Promise.reject("Erro ao tentar executar a solicitação.");
            });
    }

    async kanbanInsuranceUpdateProposal(_data) {
        return await instance2({
                method: "post",
                url: "/api/kanban/KanbanInsuranceUpdateProposal",
                data: _data
            })
            .then(data => (data.data || {}).Response)
            .catch(data => {
                if (data && data.response && data.response.data)
                    return Promise.reject(data.response.data.Errors);
                return Promise.reject("Erro ao tentar executar a solicitação.");
            });
    }
    async KanbanInsuranceUpdateProposalContractualTerms(_data) {
        return await instance2({
                method: "post",
                url: "/api/kanban/KanbanInsuranceUpdateProposalContractualTerms",
                data: _data
            })
            .then(data => (data.data || {}).Response)
            .catch(data => {
                if (data && data.response && data.response.data)
                    return Promise.reject(data.response.data.Errors);

                return Promise.reject("Erro ao tentar executar a solicitação.");
            });
    }

    async kanbanSaveBranchActivity(_data) {
        return await instance2({
                method: "post",
                url: "/api/kanban/KanbanSaveBranchActivity",
                data: _data
            })
            .then(data => (data.data || {}).Response)
            .catch(data => {
                if (data && data.response && data.response.data)
                    return Promise.reject(data.response.data.Errors);
                return Promise.reject("Erro ao tentar executar a solicitação.");
            });
    }

    async  kanbanGetBranchActivities() {
        return await instance2
            .get(`/api/kanban/GetBranchActivities`)
            .then(data => (data.data || {}).Response)
            .catch(data => {
                if (data && data.response && data.response.data)
                    return Promise.reject(data.response.data.Errors);

                return Promise.reject("Erro ao tentar executar a solicitação.");
            });
    }

    async kanbanPolicyHolderCCGSendToBroker(_data) {
        return await instance2({
                method: "post",
                url: "/api/kanban/KanbanPolicyHolderCCGSendToBroker",
                data: _data
            })
            .then(data => (data.data || {}).Response)
            .catch(data => {
                if (data && data.response && data.response.data)
                    return Promise.reject(data.response.data.Errors);
                return Promise.reject("Erro ao tentar executar a solicitação.");
            });
    }

    async kanbanGetCardOwner(cardId){
        return await instance2
            .get(
                `/api/kanban/GetKanbanCardOwner?cardId=${cardId}`
            )
            .then(data => (data.data || {}).Response)
            .catch(data => {
                if (data && data.response && data.response.data)
                    return Promise.reject(data.response.data.Errors);
                return Promise.reject("Erro ao tentar executar a solicitação.");
            });
    }

    async kanbanGetCardOwnerList(pipeId){
        return await instance2
            .get(
                `/api/kanban/GetKanbanCardOwnerList?pipeId=${pipeId}`
            )
            .then(data => (data.data || {}).Response)
            .catch(data => {
                if (data && data.response && data.response.data)
                    return Promise.reject(data.response.data.Errors);
                return Promise.reject("Erro ao tentar executar a solicitação.");
            });
    }

    async kanbanUpdateCardOwner(_data){
        return await instance2({
            method: "post",
            url: "/api/kanban/UpdateKanbanCardOwner",
            data: _data
        })
        .then(data => (data.data || {}).Response)
        .catch(data => {
            if (data && data.response && data.response.data)
                return Promise.reject(data.response.data.Errors);
            return Promise.reject("Erro ao tentar executar a solicitação.");
        });
    }
    async UpdateKanbanCardJustification(_data){
        return await instance2({
            method: "post",
            url: "/api/kanban/UpdateKanbanCardJustification",
            data: _data
        })
        .then(data => (data.data || {}).Response)
        .catch(data => {
            if (data && data.response && data.response.data)
                return Promise.reject(data.response.data.Errors);
            return Promise.reject("Erro ao tentar executar a solicitação.");
        });
    }
    //KanbanProposalRequestDocument

    ///COLOQUEI AQUI PARA DEIXAR A REGRA CENTRALIZADA POIS ESTA MTO MAU FEITO
    ///PRECISA SER REFATORADO
    async getCardDataView(card) {
        if (card.cardType === "CORRETORA_PEP") {
            return await this.getCardBrokerInfo(card.cardRef).then(response => {
                return {
                    data: response,
                    title: "Dados da Corretora",
                    type: card.cardType
                };
            });
        } else if (card.cardType === "NOMEACAO_TOMADOR") {
            return await this.getCardPolicyHolderNomeationRequestInfo(card.cardRef).then(
                response => {
                    return {
                        data: response,
                        title: "Dados da Solicitação de Nomeação",
                        type: card.cardType
                    };
                }
            );
        } else if (
            card.cardType === "ANALISE_CCG" ||
            card.cardType === "ANALISE_CCG_MANUAL"
        ) {
            return await this.getCardCCGInfo(card.cardRef).then(response => {
                return {
                    data: response,
                    title: "Dados da Solicitação de CCG",
                    type: card.cardType
                };
            });
        } else if (
            [
                "PROPOSTA",
                "GRUPO_ECONOMICO",
                "TOMADOR_QSA_PEP",
                "SEGURADO_QSA_PEP",
                "PROPOSTA_RENOVACAO"
            ].includes(card.cardType)
        ) {
            return await this.getCardProposalInfo(card.cardRef).then(response => {
                return {
                    data: response,
                    title: "Dados da Proposta",
                    type: card.cardType
                };
            });
        } else if (
            ["ENDOSSO_CANCELAMENTO", "ENDOSSO_ALTERACAO", 'ENDOSSO_ANIVERSARIO'].includes(card.cardType)
        ) {
            return await this.getCardEndorsementInfo(card.cardRef) //ambos os cards são proposals
                .then(response => {
                    return {
                        data: response,
                        title: "Dados do Endosso",
                        type: card.cardType
                    };
                });
        } else if (
            card.cardType === "ANALISE_DOCUMENTACAO_CORRETORA" ||
            card.cardType === "ANALISE_CONFORMIDADE_CORRETORA"
        ) {
            return await this.getCardBrokerInfo(card.cardRef).then(response => {
                return {
                    data: response,
                    title: "Dados da Corretora",
                    type: card.cardType,
                    cardId: card.cardId
                };
            });
        } else if (card.cardType === "RAMO_ATIVIDADE") {
            return await this.getCardRamoAtividadeInfo(card.cardRef).then(response => {
                return {
                    data: response,
                    title: "Dados do Tomador",
                    type: card.cardType
                };
            });
        } else if (card.cardType === "REQUEST_LIMIT") {
            return await this.getRequestLimitInfo(card.cardRef).then(response => {
                return {
                    data: response,
                    title: "Revisão de Limite",
                    type: card.cardType
                };
            });
        } else {
            return Promise.reject(
                "Erro! Esse card não possui uma visualização formatada."
            );
        }
    }

    async getResponsibleUserForCardMovement(cardId){
      return await instance2
      .get(`/api/Kanban/GetCardMovementUser?cardId=${cardId}`)
      .then(data => (data.data || {}).Response)
      .catch(data => {
          if (data && data.response && data.response.data)
              return Promise.reject(data.response.data.Errors);
          return Promise.reject("Erro ao tentar executar a solicitação.");
      });
    }

    async getPrioritiesKanbanCard(){
      return await instance2
      .get(`/api/Kanban/GetAllKanbanCardPriority`)
      .then(data => (data.data || {}).Response)
      .catch(data => {
          if (data && data.response && data.response.data)
              return Promise.reject(data.response.data.Errors);
          return Promise.reject("Erro ao tentar executar a solicitação.");
      });
    }

    async updatePriorityKanbanCard(_data){
      return await instance2({
        method: "post",
        url: "/api/kanban/UpdateKanbanCardPriority",
        data: _data
      })
      .then(data => (data.data || {}).Response)
      .catch(data => {
          if (data && data.response && data.response.data)
              return Promise.reject(data.response.data.Errors);
          return Promise.reject("Erro ao tentar executar a solicitação.");
      });
    }

    async getPipeStepsByPriority(pipeId, priorityIds) {
      const applicationConfig = environment.getApplicationConfig();
      const companyKey = (applicationConfig || {}).companyKey;

      return await instance({
          method: "post",
          url: `/api/kanban/v1.0/pipes/${pipeId}/steps/filterPriority`,
          baseURL: ((applicationConfig || {}).kanban || {}).url,
          headers: {
              companyKey,
              "Content-Type": "application/json"
          },
          data: {
            priorityIds: priorityIds,
            filterCardsValues: null
          }
      })
  }
}

export const kanbanProvider = new KanbanProvider();
